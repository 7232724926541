@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
.main-layout {
  padding: 30px 15px; }

.no-padding {
  padding: 0; }

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
  overflow-x: hidden; }

input:focus,
select:focus {
  box-shadow: none !important;
  outline: none !important; }

.side-margin {
  margin: 0 15px; }

.move-left {
  padding-left: 295px; }
  @media only screen and (max-width: 767px) {
    .move-left {
      padding-left: 0;
      padding-top: 50px; } }

.mt-15 {
  margin-top: 15px; }

.v-scroll {
  max-height: 440px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

.v-scroll::-webkit-scrollbar {
  width: 10px; }

.v-scroll::-webkit-scrollbar-thumb {
  background-color: #818FA5;
  border-radius: 6px; }

.last-change {
  font-size: 14px;
  margin-top: 20px;
  color: #818FA5; }
  .last-change > span {
    font-weight: 600;
    color: #818FA5; }
  .last-change:hover {
    cursor: pointer;
    opacity: 0.94;
    transition: 0.2s ease-in; }

.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: 500px; }

.crop-modal {
  max-width: 600px !important; }
  .crop-modal > div {
    width: 100%; }
    .crop-modal > div .crop-body {
      width: 100%;
      max-height: 500px !important; }
      .crop-modal > div .crop-body > div > img {
        object-fit: contain !important; }
    .crop-modal > div .modal-footer-crop {
      display: flex;
      flex-direction: column; }
      .crop-modal > div .modal-footer-crop > div {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px; }
  .crop-modal .save-cropBtn {
    background-color: #038103 !important;
    text-wrap: nowrap;
    width: 150px; }
  .crop-modal .saved-crop-message {
    color: #038103; }

.modal-body {
  text-align: center; }
  .modal-body img {
    margin-bottom: 15px; }
  .modal-body .message {
    font-size: 21px;
    font-weight: 600; }

.modal-footer {
  justify-content: center;
  border-top: 0; }
  .modal-footer button {
    width: 100px;
    background-color: #33405e !important;
    border: none; }

.modal-clasic {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1034px;
  z-index: 997;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }

.overlay-whole {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(42, 47, 53, 0.6);
  z-index: 991;
  display: none; }

.shown {
  display: block; }

.invalid-tooltip {
  display: block;
  top: 50%;
  left: unset;
  right: -150px;
  background-color: #2a2f35;
  font-size: 11px;
  height: 24px;
  max-width: unset; }
  .invalid-tooltip:before {
    content: '';
    display: block;
    position: absolute;
    top: 6.5px;
    left: -6px;
    width: 12px;
    height: 12px;
    background-color: #2a2f35;
    transform: rotate(46deg); }

.api-method-POST {
  color: orange;
  min-width: 50px;
  display: inline-block;
  font-weight: 600; }

.api-method-GET {
  color: green;
  min-width: 50px;
  display: inline-block;
  font-weight: 600; }

.api-method-PUT {
  color: purple;
  min-width: 50px;
  display: inline-block;
  font-weight: 600; }

.api-method-DELETE {
  color: red;
  min-width: 50px;
  display: inline-block;
  font-weight: 600; }

.toogle-switch {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0; }
  .toogle-switch > div {
    margin-right: 10px;
    background-color: #d2d6da;
    height: 20px;
    border-radius: 87px;
    width: 40px;
    min-width: 40px;
    padding: 2px;
    position: relative;
    transition: all 0.3s; }
    .toogle-switch > div > div {
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-radius: 8px;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: all 0.3s; }
  .toogle-switch.toogle-switch-active > div {
    background-color: #33405E; }
    .toogle-switch.toogle-switch-active > div > div {
      left: 22px; }
  .toogle-switch > span {
    font-size: 14px;
    color: #3A3F56; }

.col-up {
  margin-top: -82px; }

.col-up-right {
  z-index: 223; }

.col-up-right-top {
  position: relative;
  right: -15px;
  margin-top: -102px; }

.select-field {
  position: relative; }
  .select-field > svg {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 11px; }
  .select-field select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ''; }

.react-tagsinput {
  border-radius: 6px;
  border: 1px solid #d2d6da;
  padding-top: 0 !important;
  height: 42px;
  line-height: 30px;
  padding-left: 5px !important; }
  .react-tagsinput.react-tagsinput--focused {
    border: 1px solid #d2d6da; }
  .react-tagsinput .react-tagsinput-tag {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: #33405E;
    color: #fff;
    border: 0;
    border-radius: 3px;
    padding: 7px 11px;
    height: 35px;
    margin-top: 1px; }
    .react-tagsinput .react-tagsinput-tag .react-tagsinput-remove {
      color: #fff; }
  .react-tagsinput .react-tagsinput-input {
    margin-bottom: 0 !important;
    border: 0 !important;
    height: unset !important; }

.list-field {
  border: 1px solid #d2d6da;
  border-radius: 6px;
  padding: 15px 10px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .list-field .items {
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px; }
    .list-field .items > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      color: #3A3F56;
      margin-bottom: 13px; }
      .list-field .items > div button {
        background-color: transparent;
        border: 0;
        outline: none; }
  .list-field .input {
    position: relative;
    border: 1px dashed #d2d6da;
    border-radius: 6px;
    margin-right: 10px; }
    .list-field .input input {
      border: 0 !important;
      outline: none;
      margin-bottom: 0 !important;
      background-color: #f8f9fa !important; }
      .list-field .input input:focus {
        background-color: #fff !important; }
    .list-field .input svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -5px;
      cursor: pointer;
      pointer-events: none; }

/*========== LIST BUILDER ==========*/
.table-tabs {
  display: inline-block;
  margin-bottom: 30px;
  position: relative;
  z-index: 111; }
  @media only screen and (max-width: 767px) {
    .table-tabs {
      display: flex; } }
  .table-tabs button {
    width: 150px;
    height: 40px;
    border-radius: 6px;
    font-size: 15px;
    color: #3A416F;
    border: none;
    outline: none;
    background: transparent; }
    @media only screen and (max-width: 767px) {
      .table-tabs button {
        font-size: 13px; } }
    .table-tabs button:last-child {
      margin-left: 15px; }
    .table-tabs button:hover {
      background-color: #fff;
      transition: 0.2s ease-in;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
  .table-tabs .active {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }

.table {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .table tr {
    border-bottom: 1px solid #d2d6da; }
    .table tr:hover {
      background-color: #f8f9fa !important; }
    .table tr td {
      font-size: 15px;
      padding-left: 20px;
      color: #3A416F; }
  .table thead th {
    border: none;
    padding-left: 20px; }
  .table thead .action-td {
    height: 43.5px; }
  .table tbody tr:last-child {
    border-bottom: none !important; }

.table td {
  border-top: 0; }

.table tbody tr td.action-td {
  border-top: none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.table tbody tr .image-field {
  width: 80px;
  border-radius: 6px;
  height: 50px;
  object-fit: cover; }

.action-td {
  display: flex;
  width: 100% !important;
  max-width: 100% !important;
  border-top: none; }
  .action-td button {
    background-color: transparent;
    border: none; }

.sort-enabled {
  font-size: 13px;
  font-weight: 600;
  color: #818FA5;
  text-transform: uppercase; }

.news-counter {
  float: right;
  font-size: 14px;
  color: #3A416F;
  margin-top: 20px; }
  .news-counter .colored-green {
    color: #33405E; }
  .news-counter .colored-blue {
    color: #33405E; }

.pagination {
  margin-top: 10px; }
  .pagination .page-item {
    width: 40px;
    height: 40px;
    background-color: #fff;
    margin-left: 5px;
    border-radius: 6px !important;
    cursor: pointer; }
    .pagination .page-item:first-child {
      position: relative;
      margin-left: 0; }
      .pagination .page-item:first-child::before {
        content: '';
        position: absolute;
        top: 40%;
        left: 48%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 2px solid #3A416F;
        border-right: 2px solid #3A416F;
        transform: rotate(-136deg); }
    .pagination .page-item:last-child {
      position: relative; }
      .pagination .page-item:last-child::before {
        content: '';
        position: absolute;
        top: 43%;
        left: 40%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 2px solid #3A416F;
        border-right: 2px solid #3A416F;
        transform: rotate(42deg); }
    .pagination .page-item .page-link {
      display: block;
      line-height: 40px;
      padding: 0;
      text-align: center;
      border: none;
      font-size: 13px;
      color: #3A416F;
      border-radius: 6px; }
      .pagination .page-item .page-link:focus {
        box-shadow: none !important;
        outline: none !important; }
  .pagination .page-item-next {
    width: 40px;
    height: 40px;
    background-color: #fff;
    margin-left: 5px;
    border-radius: 6px !important;
    cursor: pointer; }
    .pagination .page-item-next:first-child {
      position: relative;
      margin-left: 0;
      width: 40px;
      height: 40px; }
      .pagination .page-item-next:first-child::before {
        content: '';
        position: absolute;
        top: 40%;
        left: 48%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 2px solid #3A416F;
        border-right: 2px solid #3A416F;
        transform: rotate(-136deg); }
    .pagination .page-item-next:last-child {
      position: relative;
      width: 40px;
      height: 40px; }
      .pagination .page-item-next:last-child::before {
        content: '';
        position: absolute;
        top: 43%;
        left: 40%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 2px solid #3A416F;
        border-right: 2px solid #3A416F;
        transform: rotate(42deg); }
    .pagination .page-item-next .page-link {
      line-height: 40px;
      width: 40px;
      height: 40px;
      padding: 0;
      text-align: center;
      border: none;
      font-size: 22px;
      color: #3A416F;
      font-weight: 500;
      border-radius: 6px; }
      .pagination .page-item-next .page-link:focus {
        box-shadow: none !important;
        outline: none !important; }
  .pagination .active .page-link {
    background-color: #33405e !important;
    color: #fff !important; }

.table-filter {
  display: flex;
  justify-content: flex-end; }
  @media only screen and (max-width: 767px) {
    .table-filter {
      flex-direction: column;
      justify-content: unset; } }
  .table-filter > label {
    display: flex;
    align-items: center;
    padding-right: 50px;
    white-space: nowrap;
    color: #818FA5;
    font-size: 13px; }
    .table-filter > label select {
      height: 42px;
      margin-right: 15px; }
  .table-filter .filter-results .form-group {
    margin-bottom: 0; }
    .table-filter .filter-results .form-group label input {
      width: 215px;
      height: 42px;
      font-size: 13px; }

.sort-up-icon {
  position: relative;
  top: 1px;
  left: 2px;
  width: 8.5px; }
  .sort-up-icon:hover {
    cursor: pointer; }

.sort-down-icon {
  position: relative;
  top: -1px;
  width: 8.5px; }
  .sort-down-icon:hover {
    cursor: pointer; }

.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 15px 10px 15px;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .list-header > div {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .list-header label {
    font-size: 21px;
    font-weight: 600;
    color: #3A416F;
    margin-bottom: 5px;
    padding-left: 15px; }
  .list-header .filter-results .form-group {
    margin-bottom: 0; }
    .list-header .filter-results .form-group label {
      margin-bottom: 0; }
      .list-header .filter-results .form-group label input {
        width: 215px;
        height: 42px;
        font-size: 13px; }

.table-modifed table {
  position: relative;
  top: -5px; }
  .table-modifed table td:nth-child(2) {
    width: 75%; }

.table-modifed .news-counter {
  margin-top: 20px; }

.ml-15 {
  margin-left: 15px; }

.mr-15 {
  margin-right: 15px; }

.modal-with-list-builder .table {
  box-shadow: none; }

.modal-with-list-builder .news-counter {
  display: none; }

.modal-with-list-builder .filter-results {
  display: none; }

.modal-with-list-builder .pagination {
  margin-left: 30px; }
  .modal-with-list-builder .pagination .page-item {
    background-color: #ebecf0; }
    .modal-with-list-builder .pagination .page-item .page-link {
      background-color: #ebecf0; }

/*========== FOOTER ==========*/
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px; }
  @media only screen and (max-width: 767px) {
    .footer {
      flex-direction: column; } }
  .footer-left {
    display: flex; }
    .footer-left button {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 50px;
      outline: none;
      border: none;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      @media (min-width: 1200px) and (max-width: 1399px) {
        .footer-left button {
          padding: 0 10px; } }
      .footer-left button:nth-child(2) {
        margin-left: 20px; }
      .footer-left button .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      .footer-left button .box-red {
        background-color: #fd5c70; }
      .footer-left button p {
        margin-bottom: 0;
        padding-left: 10px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #3A416F; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-left button p {
            font-size: 11px; } }
      .footer-left button:hover {
        background-color: #33405E;
        transition: 0.2s ease-in; }
        .footer-left button:hover .box-red {
          background-color: #fff; }
        .footer-left button:hover p {
          color: #fff; }
        .footer-left button:hover svg path,
        .footer-left button:hover svg line,
        .footer-left button:hover svg circle {
          stroke: #3A3F56; }
  .footer-right {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .footer-right {
        flex-direction: column; } }
    .footer-right .info-transparent {
      display: flex; }
      .footer-right .info-transparent .col-info {
        padding: 0 40px; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-right .info-transparent .col-info {
            padding: 0 10px; } }
        @media (min-width: 1400px) and (max-width: 1850px) {
          .footer-right .info-transparent .col-info {
            padding: 0 20px; } }
        @media only screen and (max-width: 767px) {
          .footer-right .info-transparent .col-info {
            padding: 0 5px; } }
        .footer-right .info-transparent .col-info:nth-child(2) {
          border-left: 1px solid #d2d6da;
          border-right: 1px solid #d2d6da; }
        .footer-right .info-transparent .col-info p {
          font-size: 13px;
          font-weight: 600;
          color: #818FA5;
          text-transform: uppercase;
          margin-bottom: 5px; }
        .footer-right .info-transparent .col-info span {
          font-size: 21px;
          color: #3A416F;
          text-transform: uppercase; }
          @media (min-width: 1200px) and (max-width: 1399px) {
            .footer-right .info-transparent .col-info span {
              font-size: 15px; } }
          @media (min-width: 1400px) and (max-width: 1850px) {
            .footer-right .info-transparent .col-info span {
              font-size: 17px; } }
          @media only screen and (max-width: 767px) {
            .footer-right .info-transparent .col-info span {
              font-size: 15px; } }
    .footer-right .info-colored {
      display: flex;
      background-color: #33405E;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
      padding: 10px 0; }
      .footer-right .info-colored .col-info {
        padding: 0 40px; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-right .info-colored .col-info {
            padding: 0 10px; } }
        @media (min-width: 1400px) and (max-width: 1850px) {
          .footer-right .info-colored .col-info {
            padding: 0 20px; } }
        @media only screen and (max-width: 767px) {
          .footer-right .info-colored .col-info {
            padding: 0 5px; } }
        .footer-right .info-colored .col-info:nth-child(2) {
          border-left: 1px solid #d2d6da;
          border-right: 1px solid #d2d6da; }
        .footer-right .info-colored .col-info p {
          font-size: 13px;
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          margin-bottom: 5px; }
        .footer-right .info-colored .col-info span {
          font-size: 21px;
          font-weight: 700;
          color: #fff;
          text-transform: uppercase; }
          @media (min-width: 1200px) and (max-width: 1399px) {
            .footer-right .info-colored .col-info span {
              font-size: 15px; } }
          @media (min-width: 1400px) and (max-width: 1850px) {
            .footer-right .info-colored .col-info span {
              font-size: 17px; } }
          @media only screen and (max-width: 767px) {
            .footer-right .info-colored .col-info span {
              font-size: 15px; } }

/*========== FORM BUILDER ==========*/
.add-form-container {
  padding: 25px 15px 10px 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .add-form-container .toogle-switch {
    position: relative; }
  .add-form-container > .custom-header {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .add-form-container > .custom-header h6 {
      font-size: 21px;
      font-weight: 600;
      color: #3A416F;
      margin-bottom: 20px;
      margin-left: 15px; }
    .add-form-container > .custom-header span {
      position: relative;
      font-size: 15px;
      padding-right: 30px;
      color: #3A416F; }
      .add-form-container > .custom-header span:hover {
        cursor: pointer; }
      .add-form-container > .custom-header span::after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        right: 15px;
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 1px solid #3A416F;
        border-right: 1px solid #3A416F;
        transform: rotate(133deg); }
  .add-form-container > h6 {
    font-size: 21px;
    font-weight: 600;
    color: #3A416F;
    margin-bottom: 20px;
    margin-left: 15px; }
  .add-form-container .form-group label {
    font-size: 13px;
    font-weight: 600;
    color: #3A416F; }
  .add-form-container .form-group input,
  .add-form-container .form-group select {
    height: 42px;
    border: 1px solid #d2d6da;
    border-radius: 6px;
    font-size: 13px;
    color: #818FA5;
    padding-left: 15px;
    margin-bottom: 22px; }

.submit-button {
  width: 206px;
  height: 50px;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  background-color: #33405E;
  border: none;
  outline: none;
  margin-top: 40px;
  position: relative;
  left: -15px; }
  .submit-button:hover {
    opacity: 0.94;
    transition: 0.2s ease-in; }

.option-header {
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .option-header > .add-form-container {
    background-color: unset;
    padding: unset;
    padding-left: 15px;
    box-shadow: none; }
    .option-header > .add-form-container > .form-group {
      margin-bottom: 0; }

.options-button {
  background-color: transparent;
  border: none; }
  .options-button:hover {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important; }
  .options-button:focus {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important; }
  .options-button .option-buttons-container {
    display: flex;
    align-items: center; }
    .options-button .option-buttons-container .option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: #33405E;
      border-radius: 6px; }
    .options-button .option-buttons-container .green {
      background-color: #33405E; }
    .options-button .option-buttons-container .blue {
      background-color: #33405E; }
    .options-button .option-buttons-container .red {
      background-color: #fd5c70; }
    .options-button .option-buttons-container span {
      display: inline-block;
      font-size: 13px;
      color: #3A416F;
      padding-left: 10px; }

/*========== LOGIN ==========*/
.login-page {
  position: relative;
  height: 100vh; }
  .login-page-box-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 767px) {
      .login-page-box-container {
        width: 80%; } }
    .login-page-box-container .login-page-box {
      width: 600px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
      padding: 45px 0 55px 0;
      text-align: center;
      background-color: #fff;
      border-radius: 6px; }
      @media only screen and (max-width: 767px) {
        .login-page-box-container .login-page-box {
          width: 100%;
          padding: 50px 20px; } }
      .login-page-box-container .login-page-box .col-lg-8 {
        margin: auto; }
        .login-page-box-container .login-page-box .col-lg-8 .form-group:first-child input {
          margin-bottom: 20px; }
        .login-page-box-container .login-page-box .col-lg-8 .invalid-tooltip {
          top: 20%;
          left: 97%; }
      .login-page-box-container .login-page-box .logo {
        margin-bottom: 55px; }
        @media only screen and (max-width: 767px) {
          .login-page-box-container .login-page-box .logo {
            width: 50%; } }
      .login-page-box-container .login-page-box .form .form-group {
        margin-bottom: 0; }
        .login-page-box-container .login-page-box .form .form-group label {
          display: none; }
      .login-page-box-container .login-page-box .form input {
        max-width: 360px;
        width: 100%;
        height: 50px;
        margin: auto;
        border-radius: 6px;
        border: 1px solid #d2d6da;
        padding-left: 20px;
        font-size: 13px;
        color: #818fa5; }
        .login-page-box-container .login-page-box .form input::placeholder {
          font-size: 13px;
          color: #818fa5; }
      .login-page-box-container .login-page-box .form button {
        max-width: 360px;
        width: 100%;
        height: 50px;
        margin-top: 25px;
        text-align: center;
        background-color: #33405E;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        border: none; }
        .login-page-box-container .login-page-box .form button:hover {
          cursor: pointer;
          transition: 0.3s ease-in-out;
          opacity: 0.94; }
        .login-page-box-container .login-page-box .form button:focus {
          outline: none;
          border: none;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04); }
      .login-page-box-container .login-page-box .form .toogle-switch {
        position: relative;
        left: 15px;
        padding-top: 15px; }
        .login-page-box-container .login-page-box .form .toogle-switch .switch-text {
          top: 3px; }
    .login-page-box-container .certificat {
      display: flex;
      justify-content: center;
      padding-top: 45px; }
      .login-page-box-container .certificat-content {
        padding-left: 15px; }
        .login-page-box-container .certificat-content h6 {
          font-size: 15px;
          color: #3A416F;
          margin-bottom: 0; }
        .login-page-box-container .certificat-content p {
          color: #818FA5; }

/*========== SKONTAJ ==========*/
.section-title {
  margin-bottom: 25px; }
  .section-title h1 {
    font-size: 27px;
    font-weight: 700;
    color: #3a416f;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .section-title h1 {
        font-size: 25px;
        padding-bottom: 10px; } }
  .section-title .buttons {
    display: flex;
    justify-content: space-between; }
    .section-title .buttons .ff-export {
      margin-right: 10px;
      cursor: pointer; }
      .section-title .buttons .ff-export .btn-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        background-color: #33405E;
        border-color: #33405E !important;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        border: 1px solid #33405E;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem; }
        .section-title .buttons .ff-export .btn-primary .icon {
          margin-right: 10px; }
    .section-title .buttons .file-upload-wrap {
      margin-bottom: 0;
      align-items: center; }
      .section-title .buttons .file-upload-wrap .btn-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        pointer-events: none;
        cursor: pointer;
        background-color: #33405E;
        border-color: #33405E !important;
        font-size: 15px;
        font-weight: 600; }
        .section-title .buttons .file-upload-wrap .btn-primary .icon {
          margin-right: 10px;
          background-color: #33405E;
          width: unset;
          height: unset; }
          .section-title .buttons .file-upload-wrap .btn-primary .icon svg {
            width: 15px; }
            .section-title .buttons .file-upload-wrap .btn-primary .icon svg path {
              fill: #fff; }

.title-options-group-container {
  display: flex;
  align-items: center;
  position: relative;
  top: 70px; }
  @media only screen and (max-width: 767px) {
    .title-options-group-container {
      flex-direction: column;
      align-items: unset;
      top: 0; } }
  .title-options-group-container .title-options-group {
    padding: 10px;
    margin-left: 25px; }
    @media only screen and (max-width: 767px) {
      .title-options-group-container .title-options-group {
        margin-left: 0; } }

.title-options {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .title-options {
      flex-direction: column;
      align-items: flex-start; } }
  .title-options .toogle-switch {
    position: relative;
    top: 2px; }
  .title-options-group {
    display: flex;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: none;
    padding: 10px 10px; }
    @media only screen and (max-width: 767px) {
      .title-options-group {
        display: inline-flex; } }
    .title-options-group button {
      display: flex;
      align-items: center;
      position: relative;
      top: 2px;
      background-color: transparent;
      border: none;
      margin-left: 15px;
      font-size: 13px; }
      .title-options-group button:first-child {
        margin-left: 0; }
      .title-options-group button .option {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: #33405E;
        border-radius: 6px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06); }
        .title-options-group button .option:hover {
          transition: 0.2s all;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      .title-options-group button .green {
        background-color: #33405E; }
      .title-options-group button .blue {
        background-color: #33405E; }
      .title-options-group button .red {
        background-color: #fd5c70; }
      .title-options-group button span {
        display: inline-block;
        font-size: 13px;
        color: #3A416F;
        padding-left: 10px; }
        @media only screen and (max-width: 767px) {
          .title-options-group button span {
            font-size: 11px; } }

.col-up .toogle-switch {
  position: relative;
  top: 10px;
  left: 25px; }

/*========== HEADER ==========*/
.header {
  margin-bottom: 40px; }

.search {
  position: relative;
  top: 2px; }
  .search input {
    width: 100%;
    height: 42px;
    border-radius: 6px;
    border: 1px solid #d2d6da;
    background-color: #fff;
    font-size: 13px;
    color: #818FA5;
    padding-left: 35px; }
    .search input ::placeholder {
      font-size: 13px;
      color: #818FA5; }
  .search svg {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%); }

.profile-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media only screen and (max-width: 767px) {
    .profile-container {
      justify-content: center;
      padding-top: 15px; } }

.profile {
  display: flex;
  align-items: center; }
  .profile-icon {
    position: relative;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .profile h6 {
    position: relative;
    left: -10px;
    margin-bottom: 0; }
    @media (min-width: 1200px) and (max-width: 1399px) {
      .profile h6 {
        font-size: 14px; } }
  .profile-settings {
    display: flex;
    align-items: center;
    padding-left: 40px; }
    @media (min-width: 1200px) and (max-width: 1810px) {
      .profile-settings {
        padding-left: 0; } }
    .profile-settings svg:hover {
      cursor: pointer; }
    .profile-settings .line {
      display: block;
      padding: 0 15px; }
      .profile-settings .line::before {
        content: '';
        position: relative;
        display: block;
        height: 20px;
        width: 1.5px;
        background-color: #818FA5; }
    .profile-settings .dropdown > button {
      background-color: transparent;
      border: 0;
      outline: none;
      color: #818FA5;
      padding: 0; }
      .profile-settings .dropdown > button:focus, .profile-settings .dropdown > button:hover, .profile-settings .dropdown > button:active {
        outline: none !important;
        border: 0 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        color: #818FA5 !important; }

/*========== SIDEBAR ==========*/
.sidebar {
  position: fixed;
  max-width: 240px;
  width: 100%;
  height: 100vh;
  padding: 0 0 30px 0; }
  @media only screen and (max-width: 767px) {
    .sidebar {
      width: 100%;
      max-width: unset;
      top: 0;
      left: 0;
      height: auto; } }
  .sidebar .sidebar-scroll-area {
    height: 80vh;
    overflow-y: auto;
    position: relative;
    top: -21px; }
    @media only screen and (max-width: 767px) {
      .sidebar .sidebar-scroll-area {
        height: auto; } }
  .sidebar .sidebar-scroll-area::-webkit-scrollbar {
    width: 0; }
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 30px;
    padding-left: 20px; }
    @media only screen and (max-width: 767px) {
      .sidebar-header {
        padding: 0 35px;
        padding-top: 30px;
        background-color: #fff;
        z-index: 9999;
        opacity: 1; } }
    .sidebar-header .logo {
      max-width: 150px; }
      .sidebar-header .logo:hover {
        cursor: pointer; }
    .sidebar-header .menu-icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      .sidebar-header .menu-icon-box:hover {
        cursor: pointer;
        opacity: 0.9;
        transition: 0.2s ease-in; }
  .sidebar-body {
    height: 72vh; }
    @media only screen and (max-width: 767px) {
      .sidebar-body {
        display: none; } }
    .sidebar-body .main-menu {
      padding-left: 0;
      padding-bottom: 60px; }
      .sidebar-body .main-menu li {
        list-style-type: none;
        margin-bottom: 10px;
        padding: 0 10px; }
        .sidebar-body .main-menu li .main-menu-block {
          position: relative;
          margin-bottom: 10px; }
          .sidebar-body .main-menu li .main-menu-block.dropdown-down::before {
            content: '';
            position: absolute;
            top: 45%;
            right: 20px;
            transform: translate(0, -50%);
            width: 7px;
            height: 7px;
            background: transparent;
            border-top: 2.6px solid #818FA5;
            border-right: 2.6px solid #818FA5;
            transform: rotate(-46deg); }
          .sidebar-body .main-menu li .main-menu-block.dropdown-up::before {
            content: '';
            position: absolute;
            top: 45%;
            right: 20px;
            transform: translate(0, -50%);
            width: 7px;
            height: 7px;
            background: transparent;
            border-top: 2.6px solid #818FA5;
            border-right: 2.6px solid #818FA5;
            transform: rotate(135deg); }
          .sidebar-body .main-menu li .main-menu-block > a {
            display: flex;
            align-items: center; }
            .sidebar-body .main-menu li .main-menu-block > a .icon {
              background-color: #fff;
              border-radius: 6px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
              -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
              box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14); }
              .sidebar-body .main-menu li .main-menu-block > a .icon svg {
                width: 15px; }
                .sidebar-body .main-menu li .main-menu-block > a .icon svg path {
                  fill: #33405e; }
          .sidebar-body .main-menu li .main-menu-block:hover {
            background-color: #fff;
            transition: 0.2s all;
            border-radius: 6px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
            cursor: pointer; }
            .sidebar-body .main-menu li .main-menu-block:hover > a .icon {
              background-color: #33405e; }
              .sidebar-body .main-menu li .main-menu-block:hover > a .icon svg path {
                fill: #fff; }
        .sidebar-body .main-menu li .main-menu-block-without-arrow::before {
          content: unset; }
        .sidebar-body .main-menu li a svg {
          width: 60px;
          height: 60px; }
        .sidebar-body .main-menu li a .link-text {
          font-size: 13px;
          font-weight: 400;
          color: #3A416F;
          padding-left: 10px; }
        .sidebar-body .main-menu li .main-menu-dropdown {
          display: none; }
          .sidebar-body .main-menu li .main-menu-dropdown li::marker {
            color: #818FA5; }
          .sidebar-body .main-menu li .main-menu-dropdown > li {
            position: relative;
            padding-left: 30px; }
            .sidebar-body .main-menu li .main-menu-dropdown > li:hover::marker {
              color: #3A416F; }
          .sidebar-body .main-menu li .main-menu-dropdown .rotate::after {
            top: 5px;
            transform: rotate(-226deg); }
          .sidebar-body .main-menu li .main-menu-dropdown li {
            list-style-type: disc; }
            .sidebar-body .main-menu li .main-menu-dropdown li::before {
              display: none; }
            .sidebar-body .main-menu li .main-menu-dropdown li a {
              font-size: 13px;
              font-weight: 600;
              color: #818FA5; }
              .sidebar-body .main-menu li .main-menu-dropdown li a:hover {
                color: #3A416F;
                transition: 0.2s ease-in;
                cursor: pointer; }
            .sidebar-body .main-menu li .main-menu-dropdown li .sub-dropdown {
              display: none;
              padding: 10px 0 0 20px;
              margin-bottom: 20px; }
              .sidebar-body .main-menu li .main-menu-dropdown li .sub-dropdown li {
                margin-bottom: 7px; }
                .sidebar-body .main-menu li .main-menu-dropdown li .sub-dropdown li:hover::marker {
                  color: #3A416F; }
            .sidebar-body .main-menu li .main-menu-dropdown li .open {
              display: block; }
        .sidebar-body .main-menu li .open {
          display: block; }
      .sidebar-body .main-menu .section-divider {
        display: block;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #818FA5;
        margin-top: 40px;
        margin-bottom: 25px; }
  .sidebar-footer {
    position: relative;
    height: 20vh;
    text-align: left; }
    @media only screen and (max-width: 767px) {
      .sidebar-footer {
        display: none; } }
    .sidebar-footer .fast-help {
      position: absolute;
      bottom: 5vh;
      background-image: linear-gradient(to left, #647591, #9aa6b8);
      border-radius: 10px;
      padding: 10px; }
      .sidebar-footer .fast-help svg {
        position: relative;
        left: -8px; }
      .sidebar-footer .fast-help h6 {
        font-size: 17px;
        color: #fff; }
      .sidebar-footer .fast-help p {
        font-size: 13px;
        color: #fff;
        line-height: 20px; }
      .sidebar-footer .fast-help button {
        margin-top: 20px;
        display: block;
        width: 100%;
        height: 40px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14);
        font-size: 13px;
        font-weight: 600;
        color: #3a416f;
        text-transform: uppercase;
        border: none; }
        .sidebar-footer .fast-help button:hover {
          cursor: pointer;
          transition: 0.2s ease;
          opacity: 0.94; }

.sidebar-short {
  width: 100px; }

.rotate::before {
  top: 42% !important;
  transform: rotate(134deg) !important; }

a {
  text-decoration: none !important; }
  a:hover {
    text-decoration: none !important; }

/*========== REPORTS ==========*/
.report-row {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #33405e;
  border-radius: 6px;
  margin-bottom: 20px;
  padding-left: 15px; }
  .report-row:hover {
    cursor: pointer;
    transition: 0.2s ease-in;
    opacity: 0.94; }
  .report-row svg {
    position: relative;
    top: 2px; }
  .report-row h6 {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    margin-left: 15px;
    margin-bottom: 0; }

.sortable-hoc ul {
  list-style: none;
  margin-bottom: 0; }

.sortable-hoc li {
  margin-bottom: 0;
  list-style: none; }
  .sortable-hoc li h6 {
    background-color: #EAEEF2;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 10px; }
    .sortable-hoc li h6 svg {
      margin-right: 10px;
      cursor: grab; }

body > li {
  margin-bottom: 0;
  list-style: none; }
  body > li ul {
    list-style: none; }
  body > li h6 {
    background-color: #EAEEF2;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 10px;
    color: #fff; }
    body > li h6 svg {
      margin-right: 10px;
      cursor: grab; }
  body > li > h6 {
    background-color: #818FA5;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 10px;
    color: #fff; }
    body > li > h6 svg {
      margin-right: 10px;
      cursor: grab; }

.media-gallery {
  position: relative;
  width: 100%;
  min-height: 350px; }
  .media-gallery > div {
    position: relative;
    border: 1px dashed #EAEEF2;
    width: 100%;
    padding-bottom: 50px;
    padding-top: 20px;
    height: 100%; }
    .media-gallery > div .dropzone {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%; }
    .media-gallery > div .bottom-content {
      position: absolute;
      bottom: 0;
      left: 10px;
      font-size: 12px;
      color: #818fa5;
      right: 10px; }
      .media-gallery > div .bottom-content a {
        z-index: 9999; }
      .media-gallery > div .bottom-content svg {
        margin-right: 8px;
        pointer-events: none; }
    .media-gallery > div .progress-wrap {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .media-gallery > div .progress-wrap .progress {
        width: 100%;
        height: 10px;
        background-color: #818FA5; }
        .media-gallery > div .progress-wrap .progress > div {
          height: 10px;
          background-color: #33405E; }
    .media-gallery > div .react-grid-layout > div .image-wrap {
      position: relative;
      height: 100%; }
      .media-gallery > div .react-grid-layout > div .image-wrap .delete {
        position: absolute;
        right: -10px;
        top: -10px;
        background-color: transparent;
        border: 0;
        outline: none;
        width: 20px;
        height: 20px;
        padding: 0; }
        .media-gallery > div .react-grid-layout > div .image-wrap .delete svg {
          width: 18px; }
      .media-gallery > div .react-grid-layout > div .image-wrap img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: contain;
        border: 1px solid #d4d4d4; }

.category-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .category-wrap .category {
    margin-right: 10px; }
  .category-wrap select {
    margin-bottom: 0 !important; }
  .category-wrap > button {
    border: 0;
    outline: none;
    background-color: transparent; }
    .category-wrap > button > svg {
      margin-left: auto; }

.file-upload-wrap {
  position: relative; }
  .file-upload-wrap input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer; }
  .file-upload-wrap .icon {
    background-color: #fff;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    margin-right: 10px; }
    .file-upload-wrap .icon svg path {
      fill: #3A3F56; }
  .file-upload-wrap button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    cursor: pointer;
    background-color: #33405E;
    border-color: #33405E !important;
    font-size: 15px;
    font-weight: 600; }
  .file-upload-wrap .error {
    color: red; }

.form-button {
  background-color: #3A3F56 !important;
  margin-top: 20px;
  padding: 15px 36px;
  margin-right: 30px; }

.image-select .select-field {
  border: 1px solid #d2d6da;
  border-radius: 6px;
  font-size: 13px;
  color: #818FA5;
  display: flex;
  align-items: center; }
  .image-select .select-field img {
    width: 64px;
    height: 42px;
    object-fit: contain;
    margin-right: 8px; }
  .image-select .select-field a {
    color: #818FA5;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0;
    overflow: hidden;
    height: 42px; }
    .image-select .select-field a::after {
      all: unset;
      content: ' ';
      display: block;
      border-bottom: 2px solid #818FA5;
      border-right: 2px solid #818FA5;
      transform: rotate(45deg);
      width: 6px;
      height: 6px;
      position: absolute;
      right: 10px; }
    .image-select .select-field a > img {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px; }

.image-select .dropdown-menu {
  max-height: 200px;
  overflow-y: auto; }
  .image-select .dropdown-menu > button {
    font-size: 12px;
    display: flex;
    align-items: center; }
    .image-select .dropdown-menu > button img {
      width: 64px;
      height: 42px;
      object-fit: contain;
      margin-right: 8px; }

.product-select .select-field {
  border: 1px solid #d2d6da;
  border-radius: 6px;
  font-size: 13px;
  color: #818FA5;
  display: flex;
  align-items: center; }
  .product-select .select-field input {
    border: 0;
    width: 100%;
    height: 42px;
    margin-bottom: 0; }
  .product-select .select-field img {
    width: 64px;
    height: 42px;
    object-fit: contain;
    margin-right: 8px; }
  .product-select .select-field a {
    color: #818FA5;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0;
    overflow: hidden;
    height: 42px; }
    .product-select .select-field a::after {
      all: unset;
      content: ' ';
      display: block;
      border-bottom: 2px solid #818FA5;
      border-right: 2px solid #818FA5;
      transform: rotate(45deg);
      width: 6px;
      height: 6px;
      position: absolute;
      right: 10px; }
    .product-select .select-field a > img {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px; }

.product-select .dropdown-menu {
  max-height: 200px;
  overflow-y: auto; }
  .product-select .dropdown-menu > button {
    font-size: 12px;
    display: flex;
    align-items: center; }
    .product-select .dropdown-menu > button img {
      width: 64px;
      height: 42px;
      object-fit: contain;
      margin-right: 8px; }
    .product-select .dropdown-menu > button.active {
      background-color: rgba(42, 47, 53, 0.4); }

.image-upload-wrap {
  position: relative;
  border: 1px solid #d2d6da;
  border-radius: 6px; }
  .image-upload-wrap input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 300px !important;
    width: 100% !important; }
  .image-upload-wrap > div {
    pointer-events: none;
    width: 100%;
    height: 300px;
    position: relative; }
    .image-upload-wrap > div img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .image-upload-wrap > div .progress-wrap {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0; }
      .image-upload-wrap > div .progress-wrap .progress {
        width: 50%;
        height: 10px;
        background-color: #818FA5; }
        .image-upload-wrap > div .progress-wrap .progress > div {
          height: 10px;
          background-color: #33405E; }
    .image-upload-wrap > div .bottom-content {
      background-color: rgba(255, 255, 255, 0.5);
      padding: 2px 5px;
      position: absolute;
      bottom: 15px;
      left: 15px;
      display: flex;
      align-items: center;
      font-size: 12px; }
      .image-upload-wrap > div .bottom-content p {
        margin-bottom: 0;
        margin-left: 10px; }
      .image-upload-wrap > div .bottom-content svg {
        width: 24px; }

.search-images-modal-body .form-group {
  display: flex; }
  .search-images-modal-body .form-group button {
    margin-left: 10px; }

.search-images-modal-body .search-images {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto; }
  .search-images-modal-body .search-images > div {
    width: 33.33%;
    padding: 0 5px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer; }
    .search-images-modal-body .search-images > div.active::after {
      content: ' ';
      width: 10px;
      height: 20px;
      border-bottom: 5px solid #fff;
      border-right: 5px solid #fff;
      display: block;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -5px;
      margin-top: -10px; }
    .search-images-modal-body .search-images > div.active::before {
      content: ' ';
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(51, 64, 94, 0.5);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px; }
    .search-images-modal-body .search-images > div img {
      border: 1px solid rgba(51, 64, 94, 0.5);
      background-color: rgba(51, 64, 94, 0.5);
      width: 100%;
      height: 100px;
      object-fit: contain;
      object-position: center; }

.default-wrapper {
  padding: 25px 15px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px; }

.col-header h6 {
  font-weight: 600;
  font-size: 17px; }

.col-header p {
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 0; }

.invoice-col {
  padding-left: 15px; }

.invoice-table {
  margin-top: 40px; }
  .invoice-table h2 {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #33405E; }
  .invoice-table table {
    width: 100%; }
    .invoice-table table thead tr th {
      padding: 5px;
      font-size: 13px;
      font-weight: 600;
      color: #818FA5;
      padding-left: 0; }
    .invoice-table table tbody td {
      padding: 12px 0;
      border-top: 1px solid #d2d6da;
      border-bottom: 1px solid #d2d6da;
      font-size: 15px;
      color: #33405E; }

.no-table-default-style > h2 {
  font-size: 35px;
  line-height: 53px;
  font-weight: 600;
  color: #33405E;
  margin-top: 30px;
  margin-bottom: 15px;
  padding-left: 30px; }

.no-table-default-style table {
  box-shadow: none;
  margin-bottom: 0;
  display: block; }
  .no-table-default-style table thead tr {
    padding-left: 30px; }
    .no-table-default-style table thead tr th {
      padding-left: 30px; }
      .no-table-default-style table thead tr th:nth-child(1) {
        width: 5%; }
      .no-table-default-style table thead tr th:nth-child(2) {
        width: 40%; }
      .no-table-default-style table thead tr th:nth-child(3) {
        width: 20%; }
      .no-table-default-style table thead tr th:nth-child(4) {
        width: 12%; }
      .no-table-default-style table thead tr th:nth-child(5) {
        width: 15%; }
      .no-table-default-style table thead tr th:nth-child(6) {
        width: 5%; }
  .no-table-default-style table tbody tr:last-child {
    border-bottom: 1px solid #d2d6da !important; }
  .no-table-default-style table tbody tr td {
    padding-left: 30px; }
    .no-table-default-style table tbody tr td:nth-child(1) {
      width: 5%; }
    .no-table-default-style table tbody tr td:nth-child(2) {
      width: 40%; }
    .no-table-default-style table tbody tr td:nth-child(3) {
      width: 20%; }
    .no-table-default-style table tbody tr td:nth-child(4) {
      width: 12%; }
    .no-table-default-style table tbody tr td:nth-child(5) {
      width: 15%; }
    .no-table-default-style table tbody tr td:nth-child(6) {
      width: 5%; }

.table-sum {
  float: right;
  display: flex;
  position: relative;
  right: 108px; }
  .table-sum h6 {
    font-size: 15px;
    color: #33405E;
    font-weight: 400; }
  .table-sum .left {
    text-align: right; }
    .table-sum .left h6:first-child {
      font-weight: 600;
      padding-top: 20px; }
    .table-sum .left h6:last-child {
      padding-top: 2px; }
    .table-sum .left h6:nth-child(2) {
      position: relative;
      border-bottom: 1px solid #d2d6da;
      padding-bottom: 6px; }
      .table-sum .left h6:nth-child(2)::after {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 135px;
        height: 1px;
        background-color: #d2d6da; }
    .table-sum .left h6:nth-child(3) {
      position: relative;
      border-bottom: 1px solid #d2d6da;
      padding-top: 7px;
      padding-bottom: 10px; }
      .table-sum .left h6:nth-child(3)::after {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 135px;
        height: 1px;
        background-color: #d2d6da; }
  .table-sum .right h6:first-child {
    padding-top: 20px; }
  .table-sum .right h6 span {
    position: relative;
    left: 20px; }
  .table-sum .right h6:nth-child(3) {
    padding-top: 14.5px;
    padding-bottom: 13px; }

.arrow-back {
  position: relative; }
  .arrow-back::after {
    content: '';
    position: absolute;
    top: -7px;
    width: 15px;
    height: 15px;
    background-color: transparent;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(45deg); }
  .arrow-back:hover {
    cursor: pointer; }

table .status.active {
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px; }

.orders-table table td {
  font-size: 12px; }

.qr-code button {
  background-color: #3A3F56 !important;
  margin-top: 20px;
  border: 0;
  padding: 15px 36px; }

textarea {
  height: 400px !important; }

.ff-export {
  display: flex;
  align-items: center;
  font-size: 14px; }
  .ff-export input {
    padding: 8px 20px;
    margin: 0 10px;
    border-radius: 6px;
    border: 1px solid #d2d6da;
    font-size: 13px;
    color: #818fa5; }
    .ff-export input::placeholder {
      font-size: 13px;
      color: #818fa5; }
  .ff-export .button {
    border-radius: 3px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    background-color: #33405E;
    border: none;
    outline: none;
    position: relative;
    padding: 8px 20px;
    margin-left: 10px; }
    .ff-export .button:hover {
      opacity: 0.94;
      transition: 0.2s ease-in; }

.hotSpotStyles > label {
  font-size: 13px;
  font-weight: 600; }

.hotSpotStyles .hotSpotWrapper {
  position: relative;
  border: 1px solid #d2d6da;
  border-radius: 6px; }
  .hotSpotStyles .hotSpotWrapper input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 300px !important;
    width: 100% !important; }
  .hotSpotStyles .hotSpotWrapper > div {
    width: 100%;
    height: 300px;
    position: relative; }
    .hotSpotStyles .hotSpotWrapper > div img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .hotSpotStyles .hotSpotWrapper > div .progress-wrap {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0; }
      .hotSpotStyles .hotSpotWrapper > div .progress-wrap .progress {
        width: 50%;
        height: 10px;
        background-color: #818FA5; }
        .hotSpotStyles .hotSpotWrapper > div .progress-wrap .progress > div {
          height: 10px;
          background-color: #33405E; }

.hotSpotStyles .hotSpotContainer {
  position: relative !important;
  width: 100% !important; }
  .hotSpotStyles .hotSpotContainer .hotSpotDot {
    width: 25px;
    height: 25px;
    background-color: rgba(105, 12, 12, 0.719);
    border-radius: 50%; }
    .hotSpotStyles .hotSpotContainer .hotSpotDot > div {
      position: relative; }
      .hotSpotStyles .hotSpotContainer .hotSpotDot > div > div {
        position: absolute;
        left: -60px;
        top: -30px;
        z-index: 20;
        background-color: rgba(80, 56, 56, 0.678);
        font-size: 10px;
        width: 150px;
        text-align: center;
        padding-left: 6px;
        padding-right: 6px;
        color: white; }
  .hotSpotStyles .hotSpotContainer > input {
    display: block; }
  .hotSpotStyles .hotSpotContainer > div {
    display: flex;
    justify-content: center; }
    .hotSpotStyles .hotSpotContainer > div > div {
      position: relative;
      z-index: 12; }
  .hotSpotStyles .hotSpotContainer .bottom-picker {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 2px 5px;
    position: absolute;
    bottom: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: auto; }
    .hotSpotStyles .hotSpotContainer .bottom-picker p {
      margin-bottom: 0;
      margin-left: 10px; }
    .hotSpotStyles .hotSpotContainer .bottom-picker svg {
      width: 24px; }

.hotSpotStyles .dot-mode-btn-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px; }
  .hotSpotStyles .dot-mode-btn-wrapper > button {
    font-size: 12px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border: none; }
  .hotSpotStyles .dot-mode-btn-wrapper > button:nth-child(1) {
    background-color: green; }
  .hotSpotStyles .dot-mode-btn-wrapper > button:nth-child(2) {
    background-color: #e95050; }

.pointer-event-none {
  pointer-events: none; }

.bg-unset .modal-content {
  background: unset !important;
  border: unset; }
